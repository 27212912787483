import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router";

// assets
import LoginScreen from "../assets/login_screen.png";
import logo from "../assets/Logo.png";
import authbg from "../assets/authbgside.png";

// icons
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import {
  useLoginMutation,
  useVerifyOTPMutation,
  useResendOTPMutation,
  apiSlice,
} from "../redux/apiSlice";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../redux/authSlice";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading, error }] = useLoginMutation();
  const [verifyOTP, { loading, otp_error }] = useVerifyOTPMutation();
  const [resendOTP, { resend_otp_loading, resend_otp_error }] =
    useResendOTPMutation();
  //states
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState("");

  const handleForgotPassword = () => {};
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email, password };
    try {
      // Perform the login mutation
      const result = await login(credentials).unwrap();
      console.log("Login Result:", result);

      if (result.first_login) {
        setIsLoggingIn(false);
      } else {
        localStorage.setItem("access", result.access_token);

        const userResult = await dispatch(
          apiSlice.endpoints.getUser.initiate()
        );

        if (userResult.data) {
          dispatch(loginSuccess(userResult.data));
        } else {
          console.error("Failed to fetch user data.");
        }

        navigate("/dashboard");
      }
    } catch (err) {
      console.error("Login Error:", err?.data?.error || "Login failed");
      // Optionally, display an error message to the user
    }
  };
  const handleVerifyOTP = async () => {
    try {
      const result = await verifyOTP({
        otp: otp,
      }).unwrap();
      console.log(result);
    } catch (error) {
      console.log(error.data.error);
    }
  };
  const handleResendOTP = async () => {
    try {
      const result = await resendOTP({
        email: email,
      }).unwrap();
      console.log(result);
    } catch (error) {
      console.log(error.data.error);
    }
  };

  return (
    <div className="relative lg:flex items-center w-screen h-screen">
      <div className="w-full lg:w-1/2">
        <img
          src={LoginScreen}
          // src={authbg}
          className="object-cover w-full h-screen flex-1"
          alt="Login Screen"
        />
      </div>
      <div
        className="bg-[url('/src/assets/authbgside.png')] bg-cover bg-center w-full flex justify-center items-center absolute top-1/2 left-1/2
         -translate-x-1/2 -translate-y-1/2 lg:transform-none lg:top-0  lg:translate-x-0 lg:translate-y-0 lg:static lg:w-1/2  lg:items-center lg:justify-start h-full"
      >
        {isLoggingIn ? (
          <div
            className="
        p-7 bg-white         
      "
          >
            <img src={logo} className="object-fill scale-75" alt="Logo" />

            <div className="pl-6">
              <div className="my-8">
                <h3 className="text-[#1e3957] text-2xl font-bold">Login</h3>
                <p>Login to access your Yaqen account</p>
              </div>
              <form
                action=""
                className="min-w-80"
                onSubmit={(e) => handleFormSubmit(e)}
              >
                {/* Email Input */}
                <div className="relative z-0 w-full mb-6 group mt-8">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-slate-600 peer"
                    placeholder=" "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label
                    htmlFor="email"
                    className="
                absolute text-sm text-gray-500 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] 
                peer-focus:left-0 peer-focus:text-zinc-700 
                peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 
                peer-focus:scale-75 peer-focus:-translate-y-6
              "
                  >
                    Email
                  </label>
                </div>

                {/* Password Input */}
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    className="block py-2.5 px-0 pr-10 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-slate-600 peer"
                    placeholder=" "
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label
                    htmlFor="password"
                    className="
                absolute text-sm text-gray-500 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] 
                peer-focus:left-0 peer-focus:text-zinc-700 
                peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 
                peer-focus:scale-75 peer-focus:-translate-y-6
              "
                  >
                    Password
                  </label>
                  {/* Eye Icon */}
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VscEyeClosed className="text-gray-500" />
                    ) : (
                      <VscEye className="text-gray-500" />
                    )}
                  </div>
                </div>

                {/* Remember Me & Forgot Password */}
                <div className="flex justify-between">
                  <div>
                    <input type="checkbox" id="remember_me" />
                    <label htmlFor="remember_me" className="ml-2">
                      Remember me
                    </label>
                  </div>
                  <button
                    type="button"
                    onClick={handleForgotPassword}
                    className="text-red-500"
                  >
                    Forgot Password
                  </button>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="bg-[#1e3957] text-white w-full rounded-md mt-5 py-2"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div className="p-7 bg-white       ">
            <img src={logo} className="object-fill scale-75" alt="Logo" />

            <div className="pl-6">
              <div className="my-8">
                <h3 className="text-[#1e3957] text-2xl font-bold">
                  Two-Step Verification
                </h3>
                <p className="font-normal text-sm">
                  Please enter the OTP (One Time Password) to verify your
                  account. <br />A code has been sent to your email
                </p>
              </div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "40px",
                      height: "40px",
                      margin: "0 5px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      textAlign: "center",
                      fontSize: "18px",
                    }}
                  />
                )}
              />
              <div className="flex justify-between mt-5">
                <p>Didn't receive the code?</p>
                <button onClick={handleResendOTP} className="text-red-500">
                  Resend OTP
                </button>
              </div>
            </div>
            <button
              onClick={handleVerifyOTP}
              className="bg-[#1e3957] text-white w-full rounded-md mt-5 py-2 ml-6"
            >
              Verify
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Auth;
