import React, { useEffect, useMemo, useState } from "react";
import { useGetUserQuery } from "../redux/apiSlice";
import Select from "react-dropdown-select";
import "../styles.css";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useSelector } from "react-redux";
import { GoPencil } from "react-icons/go";
import { MdOutlineRemoveRedEye } from "react-icons/md";

// Dashboard Table

const DashboardTable = () => {
  const handleEdit = (params) => {
    const row = params.data;
    alert("Edit action on:", row);
  };

  const handleView = (params) => {
    const row = params.data;
    alert("View action on:", row);
  };
  const actionCellRenderer = (params) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          height: "100%",
        }}
      >
        <GoPencil
          style={{ cursor: "pointer", color: "#000" }}
          onClick={() => handleEdit(params)}
          title="Edit"
        />
        <MdOutlineRemoveRedEye
          style={{ cursor: "pointer", color: "#000" }}
          onClick={() => handleView(params)}
          title="View"
        />
      </div>
    );
  };
  const [rowData, setRowData] = useState([
    {
      "Full Name": "Ahmed",
      Email: "ahmes@dummymail.com",
      Status: "Admin",
      Permissions: "All",
      "Last Login": "some date",
    },
    {
      "Full Name": "Ahmed",
      Email: "Model Y",
      Status: true,
      Permissions: 64950,
      "Last Login": true,
    },
    {
      "Full Name": "Ahmed",
      Email: "ahmes@dummymail.com",
      Status: "Admin",
      Permissions: "All",
      "Last Login": "some date",
    },
  ]);

  const [colDefs, setColDefs] = useState([
    { field: "Full Name" },
    { field: "Email" },
    { field: "Permissions" },
    { field: "Status" },
    { field: "Last Login" },
    {
      headerName: "",
      field: "Actions",
      cellRenderer: actionCellRenderer,
      sortable: false,
      filter: false,
      width: "100%",
    },
  ]);
  const defaultColumnDef = useMemo(() => {
    return {
      flex: 1,
    };
  });
  return (
    <div
      className="ag-theme-quartz mt-4 h-screen w-full"
      style={{ height: `calc(100vh - 156px)` }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColumnDef}
      />
    </div>
  );
};

const UserManagement = () => {
  // const { user, isAuthenticated, roles, permissions } = useSelector(
  //   (state) => state.auth
  // );
  // console.log(roles, permissions);
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  useEffect(() => {
    // get the users
  }, []);
  return (
    <div className="">
      {/* header */}
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1>Users</h1>
          <p className="hidden md:inline text-gray-400 text-sm">
            {}Number of Users
          </p>
        </div>
        <div className="flex gap-3">
          <Select
            options={options}
            // onChange={(values) => this.setValues(values)}
            placeholder="Roles"
            style={{
              // width: "200px",
              borderRadius: "6px",
            }}
          />

          <Select
            options={options}
            // onChange={(values) => this.setValues(values)}
            placeholder="Status"
            style={{
              width: "100px",
              borderRadius: "6px",
            }}
          />
        </div>
      </div>
      {/* header */}

      {/* content */}
      <div>
        <DashboardTable />
      </div>
      {/* content */}
    </div>
  );
};

export default UserManagement;
