import React from "react";
import { Outlet } from "react-router";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  // const { user, isAuthenticated, roles, permissions } = useSelector(
  //   (state) => state.auth
  // );
  return (
    <div className="p-4 flex">
      <Sidebar />
      <div className="flex flex-col w-full pl-3">
        {/* <Header user={user} /> */}
        <Header />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
