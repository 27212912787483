import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const DOMAIN = "https://836e-125-63-73-50.ngrok-free.app";
const BASE_URL = `${DOMAIN}/api/`;

// Custom fetchBaseQuery to include the Authorization header if the token exists
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("access");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem("access");
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  // baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "user_login/",
        method: "POST",
        body: credentials,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (credentials) => ({
        url: "verify-otp/",
        method: "POST",
        body: credentials,
      }),
    }),
    resendOTP: builder.mutation({
      query: (credentials) => ({
        url: "send-otp/",
        method: "POST",
        body: credentials,
      }),
    }),
    getUser: builder.query({
      query: (credentials) => ({
        url: "profile/",
        method: "GET",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useVerifyOTPMutation,
  useResendOTPMutation,
  useGetUserQuery,
} = apiSlice;
