import React from "react";
import { Link } from "react-router-dom";

// assets
import logo from "../assets/logo_dark.png";
import { LuLayoutDashboard, MdSwapHoriz, MdExitToApp } from "react-icons/lu";
import { PiUsersBold, PiUsersThree } from "react-icons/pi";
import { MdOutlinePersonSearch, MdOutlineArchive } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { FaRegFileAlt } from "react-icons/fa";
import { IoPower } from "react-icons/io5";

const Sidebar = () => {
  return (
    <div className="flex flex-col items-center lg:items-start bg-[#1e3957] rounded-md min-h-[calc(100vh-32px)] sm:w-10 lg:w-64 transition-all">
      <div className="flex justify-center items-center p-4 text-white">
        <span className="hidden lg:block">
          <img src={logo} alt="Logo" />
        </span>
      </div>

      {/* Navigation links/icons */}
      <div className="flex flex-col space-y-4 p-4 flex-grow">
        <Link to="/dashboard" className="text-white flex items-center gap-3">
          <LuLayoutDashboard color="white" />{" "}
          <span className="hidden lg:inline">Dashboard</span>
        </Link>
        <Link to="/users" className="text-white flex items-center gap-3">
          <PiUsersBold color="white" />{" "}
          <span className="hidden lg:inline">User Management</span>
        </Link>
        <Link
          to="/investigations"
          className="text-white flex items-center gap-3"
        >
          <MdOutlinePersonSearch color="white" />{" "}
          <span className="hidden lg:inline">Investigations</span>
        </Link>
        <Link to="/reports" className="text-white flex items-center gap-3">
          <TbReportAnalytics color="white" />{" "}
          <span className="hidden lg:inline">Reports</span>
        </Link>
        <Link to="/data-sources" className="text-white flex items-center gap-3">
          <FaRegFileAlt color="white" />{" "}
          <span className="hidden lg:inline">Data Sources</span>
        </Link>
        <Link to="/archived" className="text-white flex items-center gap-3">
          <MdOutlineArchive color="white" />{" "}
          <span className="hidden lg:inline">Archived Data</span>
        </Link>
      </div>

      {/* Additional links at the bottom */}
      <div className="flex flex-col space-y-4 p-4 w-full">
        <Link
          to="/switch-user"
          className="text-white flex items-center gap-3 w-full text-center"
        >
          <PiUsersThree color="white" />{" "}
          <span className="hidden lg:inline">Switch Users</span>
        </Link>
        <Link
          to="/logout"
          className="text-white flex items-center gap-3 w-full text-center"
        >
          <IoPower color="white" />{" "}
          <span className="hidden lg:inline">Logout</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
