import { Route, Routes } from "react-router-dom";
import "./App.css";
import {
  Auth,
  Dashboard,
  UserManagement,
  Investigation,
  Report,
  DataSources,
  Archived,
  Profile,
  Layout,
  SwitchUser,
} from "./Pages";
import Result from "./Pages/Result";

const App = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/auth" element={<Auth />} />

      {/* Protected Routes */}
      <Route element={<Layout />}>
        <Route
          path="/dashboard"
          element={
            // <ProtectedRoute>
            <Dashboard />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            // <ProtectedRoute>
            <UserManagement />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/investigations"
          element={
            // <ProtectedRoute>
            <Investigation />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/reports"
          element={
            // <ProtectedRoute>
            <Report />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/data-sources"
          element={
            // <ProtectedRoute>
            <DataSources />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/archived"
          element={
            // <ProtectedRoute>
            <Archived />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/switch-user"
          element={
            // <ProtectedRoute>
            <SwitchUser />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/results"
          element={
            // <ProtectedRoute>
            <Result />
            // </ProtectedRoute>
          }
        />
      </Route>

      {/* Fallback Route */}
      <Route path="*" element={<Auth />} />
    </Routes>
  );
};

export default App;
