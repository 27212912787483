import React, { useState } from "react";
import InvestigationDropdown from "../Components/InvestigationDropdown";

import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { useNavigate } from "react-router";

const Investigation = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  //state for investigation search type
  const [selectedOption, setSelectedOption] = useState({
    id: 1,
    label: "Username",
    icon: "FaUser",
  });

  const handleSearch = () => {
    navigate("/results", {
      state: {
        searchType: selectedOption,
        searchText: input,
      },
    });
  };
  return (
    <div className="flex  items-center justify-center h-full">
      <div className="flex flex-col justify-center items-center gap-3">
        <AiOutlineUser size={40} />
        <h1>Profile Investigation</h1>
        <p>
          Search for profile based on Phone, Domain IP, Social Media and many
          more information.
        </p>
        <div className="flex gap-4 w-[80%]">
          <InvestigationDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <div className="flex items-center gap-3 border border-none rounded-md px-3 py-1 w-full bg-[#EFF3F5]">
            <HiOutlineMagnifyingGlass />
            <input
              type="text"
              placeholder="Enter input parameter"
              className="appearance-none outline-none border-none w-full bg-[#EFF3F5]"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
        </div>
        <button
          onClick={() => handleSearch()}
          className="bg-[#1e3957] text-white px-4 py-2 rounded-md w-[70%]"
        >
          Search
        </button>
      </div>
      <div></div>
    </div>
  );
};

export default Investigation;
