import React, { useEffect, useState } from "react";
import InvestigationDropdown from "../Components/InvestigationDropdown";
import { useLocation } from "react-router";
import "../styles.css";

// assets
import {
  MdOutlineRefresh,
  MdOutlineFileDownload,
  MdOutlineShare,
  MdOutlineLocalPrintshop,
} from "react-icons/md";
import { SiSimpleanalytics } from "react-icons/si";
import gemini from "../assets/gemini_icon.svg";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const TableComponent = () => {
  const columnDefs = [
    {
      headerName: "",
      field: "avatar",
      cellRenderer: (params) => {
        return (
          <img
            src={params.value}
            alt="Avatar"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              objectFit: "cover",
              border: "1px solid black",
              alignItems: "center",
            }}
          />
        );
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      width: "70px",
    },
    { headerName: "First Name", field: "firstName", width: 180 },
    { headerName: "Last Name", field: "lastName", width: 180 },
    { headerName: "Age", field: "age", width: 90 },
    { headerName: "Country", field: "country", width: 150 },
  ];

  const rowData = [
    {
      avatar: gemini,
      firstName: "John",
      lastName: "Doe",
      age: 25,
      country: "USA",
    },
    {
      avatar: gemini,
      firstName: "Jane",
      lastName: "Smith",
      age: 30,
      country: "Canada",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "John",
      lastName: "Doe",
      age: 25,
      country: "USA",
    },
    {
      avatar: gemini,
      firstName: "Jane",
      lastName: "Smith",
      age: 30,
      country: "Canada",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "John",
      lastName: "Doe",
      age: 25,
      country: "USA",
    },
    {
      avatar: gemini,
      firstName: "Jane",
      lastName: "Smith",
      age: 30,
      country: "Canada",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "John",
      lastName: "Doe",
      age: 25,
      country: "USA",
    },
    {
      avatar: gemini,
      firstName: "Jane",
      lastName: "Smith",
      age: 30,
      country: "Canada",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "John",
      lastName: "Doe",
      age: 25,
      country: "USA",
    },
    {
      avatar: gemini,
      firstName: "Jane",
      lastName: "Smith",
      age: 30,
      country: "Canada",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "John",
      lastName: "Doe",
      age: 25,
      country: "USA",
    },
    {
      avatar: gemini,
      firstName: "Jane",
      lastName: "Smith",
      age: 30,
      country: "Canada",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "John",
      lastName: "Doe",
      age: 25,
      country: "USA",
    },
    {
      avatar: gemini,
      firstName: "Jane",
      lastName: "Smith",
      age: 30,
      country: "Canada",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    {
      avatar: gemini,
      firstName: "John",
      lastName: "Doe",
      age: 25,
      country: "USA",
    },
    {
      avatar: gemini,
      firstName: "Jane",
      lastName: "Smith",
      age: 30,
      country: "Canada",
    },
    {
      avatar: gemini,
      firstName: "Mike",
      lastName: "Johnson",
      age: 35,
      country: "UK",
    },
    // Add more rows as needed
  ];

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: "autoHeight",
    suppressCellSelection: true,
  };
  const paginationPageSizeSelector = [10, 20, 50, 100];

  return (
    <div
      className="ag-theme-quartz"
      style={{ width: "100%", height: "calc(100vh-80px)" }}
    >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        gridOptions={gridOptions}
        pagination={true}
        paginationPageSizeSelector={paginationPageSizeSelector}
        // domLayout="autoHeight"
      />
    </div>
  );
};

const Result = () => {
  const location = useLocation();
  const { searchType, searchText } = location.state;
  const [selectedOption, setSelectedOption] = useState(searchType);

  useEffect(() => {
    setSelectedOption(searchType);
  }, [searchType]);

  return (
    <div className="w-full flex flex-col h-full gap-3">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h2>Result</h2>
          <p className="text-gray-400 font-light text-sm">Number of results</p>
        </div>

        <div className="flex gap-3 items-center">
          <InvestigationDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <div className="w-full bg-[#EFF3F5] rounded-xl flex items-center h-full gap-5 px-3">
            <p>{searchText}</p>
            <input
              type="text"
              placeholder="Enter input parameter"
              className="appearance-none outline-none border-none w-full bg-[#EFF3F5]"
              // value={input}
              // onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <button
            // onClick={() => handleSearch()}
            className="bg-[#1e3957] text-white px-4 py-2 rounded-md "
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex gap-3">
          <button className="flex text-gray-500 items-center gap-2 text-sm">
            <MdOutlineRefresh /> Refresh
          </button>
          <button className="flex text-gray-500 items-center gap-2 text-sm">
            <MdOutlineFileDownload /> Download
          </button>
          <button className="flex text-gray-500 items-center gap-2 text-sm">
            <MdOutlineShare /> Share
          </button>
          <button className="flex text-gray-500 items-center gap-2 text-sm">
            <MdOutlineLocalPrintshop /> Print
          </button>
        </div>
        <div className="flex gap-3">
          <button className="flex gap-3 items-center border-[1px] border-gray-300 rounded-md px-4 py-1">
            <SiSimpleanalytics />
            Analytics
          </button>
          <div className="flex justify-center items-center p-[1px] bg-gradient-to-r from-gemini-blue to-gemini-pink rounded-md">
            <button className="flex gap-3 items-center bg-white border border-transparent rounded-md px-4 py-1 w-full">
              <img src={gemini} alt="Gemini Icon" />
              AI Assistant
            </button>
          </div>
        </div>
      </div>
      <TableComponent />
    </div>
  );
};

export default Result;
