import React, { useState, useRef, useEffect } from "react";
import { IconContext } from "react-icons";

// Assets
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FaUser, FaPhone, FaGlobe, FaImage, FaGavel } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
import IP from "../assets/ip_icon.svg";

const options = [
  { id: 1, label: "Username", icon: "FaUser" },
  { id: 2, label: "IP Address", icon: "IP" },
  { id: 3, label: "Phone", icon: "FaPhone" },
  { id: 4, label: "Sanctions", icon: "FaGavel" },
  { id: 5, label: "Email", icon: "MdOutlineMailOutline" },
  { id: 6, label: "Domain", icon: "FaGlobe" },
  { id: 7, label: "Image", icon: "FaImage" },
];

const renderIcon = (iconName) => {
  switch (iconName) {
    case "FaUser":
      return <FaUser />;
    case "FaPhone":
      return <FaPhone />;
    case "FaGavel":
      return <FaGavel />;
    case "MdOutlineMailOutline":
      return <MdOutlineMailOutline />;
    case "FaGlobe":
      return <FaGlobe />;
    case "FaImage":
      return <FaImage />;
    case "IP":
      return <img src={IP} alt="IP" className="w-5 h-5 text-gray-300" />;
    default:
      return null; // Default case to handle unknown icons
  }
};

const Dropdown = ({ selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Default option change handler
  const defaultOptionChange = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleOptionChange = (option) => {
    if (setSelectedOption) {
      setSelectedOption(option);
    } else {
      defaultOptionChange(option);
    }
    setIsOpen(false);
  };

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <div
      className="relative text-left flex flex-col items-center "
      ref={dropdownRef}
    >
      <button
        type="button"
        className="inline-flex justify-center items-center px-4 h-full py-2 bg-[#EFF3F5] border-none  rounded-xl  focus:outline-none"
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <IconContext.Provider value={{ className: "text-l" }}>
          {renderIcon(selectedOption.icon)}
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "ml-2 text-sm" }}>
          {isOpen ? <FaCaretUp /> : <FaCaretDown />}
        </IconContext.Provider>
      </button>

      {isOpen && (
        <div
          className="origin-top-left absolute mt-12 w-96 rounded-md shadow-lg bg-[#EFF3F5] ring-1 ring-black ring-opacity-5 z-10"
          role="menu"
          aria-orientation="vertical"
        >
          <div className="grid grid-cols-2 gap-4 p-2" role="none">
            {options.map((option) => (
              <label
                key={option.id}
                className="flex items-center p-2 text-sm text-gray-700 hover:bg-gray-100 rounded cursor-pointer"
                role="menuitemradio"
                aria-checked={selectedOption.id === option.id}
              >
                <input
                  type="radio"
                  name="dropdown"
                  checked={selectedOption.id === option.id}
                  onChange={() => handleOptionChange(option)}
                  className="form-radio h-4 w-4 text-[#1e3957] cursor-pointer"
                />
                <span className="ml-2 flex items-center">
                  <IconContext.Provider
                    value={{ className: "text-lg text-gray-300" }}
                  >
                    {renderIcon(option.icon)}
                  </IconContext.Provider>
                  <span className="ml-2">{option.label}</span>
                </span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
