// src/store/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  roles: [],
  permissions: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user_data;
      state.roles = [action.payload.user_data.role];
      state.permissions = action.payload.all_permissions;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.roles = [];
      state.permissions = {};
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;
export default authSlice.reducer;
