import React from "react";

const Header = ({ user }) => {
  return (
    <header
      style={{
        padding: "10px",
        backgroundColor: "#f4f4f4",
        marginBottom: "20px",
      }}
    >
      <h1>Header </h1>
    </header>
  );
};

export default Header;
